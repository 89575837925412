@import '@moda/om';
@import '../../styles/variables';

.PageLayout {
  min-height: 100vh;

  &__content {
    flex-grow: 1;
  }
}
