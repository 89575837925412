@import '~@moda/om';

.ShippingAddressForm {
  padding-top: space(6);

  &__form {
    background: color('snow');
    padding: space(6);
    border-radius: space(1);
    box-shadow: 0 space(4) space(4) -1rem color(ink, 0.2);
  }

  &__ship-to {
    background: color('snow');
    padding: space(6);
    border-radius: space(1);
    box-shadow: 0 space(4) space(4) -1rem color(ink, 0.2);
  }
}

// stylelint-disable plugin/selector-bem-pattern
button.ShippingAddressForm__next-button {
  border: 2px solid color(cornflower-blue);
  color: color(cornflower-blue);
  font-weight: bold;

  &:hover {
    background-color: color(cornflower-blue);
    border: 2px solid color(cornflower-blue);
    color: color('snow');
  }
}
