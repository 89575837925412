@import '~@moda/om';
@import '~@moda/om/dist/styles';
@include global-styles;

.App {
  @include text(body1);

  color: color(ink);
  // stylelint-disable-next-line
  .Clickable {
    background-color: color('snow');
  }
}
