@import '~@moda/om';

.ShippingInformation {
  background-color: color('snow');
  padding: spacing(6, 8);
  border-radius: space(1);
  box-shadow: 0 space(4) space(4) -1rem color(ink, 0.2);

  &__carrier,
  &__tracking {
    width: 100%;
  }
}
