@import '@moda/om';
@import '../../styles/variables';

.FulfillAllModal {
  text-align: center;
  margin: 0 auto;
  width: 85%;
  z-index: z(one);

  @include breakpoint(sm) {
    width: 50%;
  }

  &__confirm-modal-content,
  &__success-modal-content {
    background-color: color('snow');
    padding: space(5);

    @include breakpoint(sm) {
      padding: space(8);
    }
  }

  &__success-icon {
    color: color(money-good);
  }

  &__handshake-icon {
    color: color(cornflower-blue);
  }
}

// stylelint-disable plugin/selector-bem-pattern
button.FulfillAllModal__cancel-button,
button.FulfillAllModal__confirm-button,
a.FulfillAllModal__confirm-button {
  @include text(h6);

  font-weight: bold;
}

button.FulfillAllModal__confirm-button,
a.FulfillAllModal__confirm-button {
  border: 2px solid color(cornflower-blue);
  color: color(cornflower-blue);

  &:hover {
    border-color: color(cornflower-blue);
    color: color(cornflower-blue);
  }
}

button.FulfillAllModal__cancel-button {
  &:hover {
    text-decoration: none;
  }
}
