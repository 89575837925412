@import '~@moda/om';

.ShipmentReview {
  padding-top: space(7);

  &__details {
    background-color: color('snow');
    border-radius: space(1);
    box-shadow: 0 space(4) space(4) -1rem color(ink, 0.2);
    padding: spacing(6, 7);
  }

  &__heading {
    margin: spacing(2, 0);
  }

  &__heading-text,
  &__row-header {
    font-weight: bold;
  }

  &__table {
    border-collapse: collapse;
    text-align: left;
    width: 100%;
    height: 100%;
    border: 1px solid color(elephant);
  }

  &__item-logo {
    max-width: space(10);
    max-height: space(10);
  }

  &__thead {
    border: 1px solid color(elephant);
  }

  &__row-data {
    vertical-align: top;
    padding-top: space(2);
  }

  &__row-header {
    @include text(h6);

    padding: spacing(4, 0);
  }
}
