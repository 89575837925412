@import '~@moda/om';
@import '../../styles/z-indexes';

.PurchaseOrders {
  padding: spacing(7, 8);
  flex: 1;
  flex-direction: column;
  max-height: 100%;
  box-sizing: border-box;

  // stylelint-disable-next-line
  .SearchInput__clear {
    width: 2.5rem;
    height: 2.5rem;
    top: auto;
    right: 1px;
    bottom: 1px;
  }

  &__header {
    flex-wrap: wrap;
  }

  &__arrow-bar {
    width: 100%;
    display: inline-flex;
    overflow: hidden;
    min-height: space(8);
  }

  &__arrow {
    position: absolute;
    height: space(8);
    width: space(8);
    transform: rotate(-45deg);
    border-right: 2px solid color(cornflower-blue);
    border-bottom: 2px solid color(cornflower-blue);
    border-radius: 0 0 5px;
    box-sizing: border-box;
    right: -20px;
    z-index: z(one);
  }

  &__item {
    @include text(h5);

    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    width: 50%;
    color: color(fog);
    border: 2px solid color(elephant);
    background-color: color('snow');

    &:first-child {
      border-right: none;
      border-radius: spacing(2, 0, 0, 2);
    }

    &:last-child {
      border-radius: spacing(0, 2, 2, 0);
      border-left: none;
    }

    &--active {
      border-top: 2px solid color(cornflower-blue);
      border-bottom: 2px solid color(cornflower-blue);
      color: color(ink);

      &:first-child {
        border-left: 2px solid color(cornflower-blue);
      }

      &:last-child {
        border-right: 2px solid color(cornflower-blue);
      }
    }
  }

  &__orders-table {
    @include text(h5);

    width: 100%;
    align-items: center;
    justify-content: center;
    border-radius: space(2);
    line-height: space(6);
    text-align: center;
    border-collapse: collapse;
    border: 1px solid color(cornflower-blue);
  }

  &__thead {
    background-color: color(noise);
    position: sticky;
    top: 4.5rem;
    z-index: z(nav);
    background-clip: padding-box;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      border-bottom: 1px solid color(elephant);
    }
  }

  &__row {
    cursor: pointer;
    padding: space(6);
    color: color(ink);
    justify-content: space-around;
    text-decoration: none;
    border-bottom: 1px solid color(elephant);

    &:hover {
      background-color: color(noise);
      background-clip: padding-box;
    }
  }

  &__row-header,
  &__row-data {
    @include text(h6);

    width: space(11);
    padding: space(6);

    @include breakpoint(xs) {
      padding: space(3);
    }

    @include breakpoint(lg) {
      padding: space(6);
    }
  }

  &__row-header {
    cursor: default;
  }

  &__tbody {
    @include overflow-scroll(y);

    max-height: 50vh;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    scroll-snap-type: y mandatory;

    // Disable scrollbar
    -ms-overflow-style: none; // IE 10+
    scrollbar-width: none; // Firefox

    &::-webkit-scrollbar {
      display: none; // Safari and Chrome
    }
  }
}

// stylelint-disable plugin/selector-bem-pattern
button.Button.PurchaseOrders__status {
  @include text(eyebrow);

  width: space(10);
  word-wrap: break-word;
  font-weight: bold;
}

button.Button.Changes_Requested {
  color: color(klein-blue);
  background-color: color(cornflower-blue);
}

button.Button.Confirmed,
button.Button.Draft {
  color: color(forest-green);
  background-color: color(seafoam);
}

button.Button.Fully_Received,
button.Button.Fully_Fullfilled {
  color: color(klein-blue);
  background-color: color(elephant);
}

button.Button.Receiving,
button.Button.Changes_Accepted {
  color: color(burnt-orange);
  background-color: color(blush);
}

button.Button.Billed {
  background-color: color(mint);
  color: color(money-good);
}

.Button.PurchaseOrders__create-shipment {
  @include text(eyebrow);

  height: space(7);
  word-wrap: break-word;
  font-weight: bold;
  color: color('snow');
  border: 2px solid color(cornflower-blue);
  border-radius: space(2);
  background-color: color(cornflower-blue);

  &:hover {
    color: color(cornflower-blue);
    background-color: color('snow');
    border: 2px solid color(cornflower-blue);
  }
}

a.Button.PurchaseOrders__create-shipment--disabled {
  color: color('snow');
  background-color: color(fog);
}
