@import '@moda/om';

.OrdersPage {
  &__container {
    $self: &;

    position: relative;
    display: inline-block;
  }

  &__input {
    flex: 1;
    width: auto;
    // stylelint-disable-next-line
    input {
      width: auto;
      // stylelint-disable-next-line
      &,
      &:focus {
        border-bottom: 1px solid color(elephant);
      }
    }
  }
}
