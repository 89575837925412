@import '@moda/om';

.OrderDetailPage {
  background-color: color(noise);

  &__nav {
    align-items: baseline;
    padding: spacing(4, 6);
    background-color: color('snow');
    flex-wrap: wrap;
  }
}

// stylelint-disable plugin/selector-bem-pattern
button.OrderDetailPage__button-request-change,
button.OrderDetailPage__button-confirm-po,
a.OrderDetailPage__button-return-po {
  @include text(h6);

  font-weight: bold;
  border-radius: space(2);
}

button.OrderDetailPage__button-request-change {
  border: 2px solid color(burnt-orange);
  color: color(burnt-orange);

  &:hover {
    background-color: color(burnt-orange);
    border: 2px solid color(burnt-orange);
    color: color('snow');
  }
}

button.OrderDetailPage__button-confirm-po,
a.OrderDetailPage__button-return-po {
  border: 2px solid color(cornflower-blue);
  color: color(cornflower-blue);

  &:hover {
    background-color: color(cornflower-blue);
    border: 2px solid color(cornflower-blue);
    color: color('snow');
  }
}
