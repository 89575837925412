@import '@moda/om';

.ShipmentPage {
  background-color: color(noise);
  flex: 1;

  // stylelint-disable-next-line
  .SearchInput__clear {
    width: 2.5rem;
    height: 2.5rem;
    top: auto;
    right: 1px;
    bottom: 1px;
  }

  &__search {
    align-self: center;
    padding-top: space(6);
  }

  &__input {
    width: space(12);
  }

  &__main {
    padding: spacing(0, 11);

    @include breakpoint(md, $prop: max-width) {
      padding: spacing(0, 6);
    }

    @include breakpoint(sm, $prop: max-width) {
      padding: spacing(0, 4);
    }

    // stylelint-disable-next-line
    .Overlay {
      position: fixed;
    }
  }
}

// stylelint-disable plugin/selector-bem-pattern
button.ShipmentPage__fulfill-button {
  @include text(eyebrow);

  height: 2.6rem;
  border: 2px solid color(cornflower-blue);
  color: color(cornflower-blue);
  font-weight: bold;

  &:hover {
    border-color: color(cornflower-blue);
    color: color(cornflower-blue);
  }
}
