@import '~@moda/om';

.SelectionPanel {
  background: color('snow');
  border-radius: space(1);
  box-shadow: 0 space(4) space(4) -1rem color(ink, 0.2);
  min-height: 600px;
  max-height: 900px;
  padding: 0;

  &__selected {
    max-height: 900px;
    min-width: 300px;
  }

  &__selected--container {
    overflow-y: auto;
    height: 100%;
    border-bottom: 1px solid color(elephant);
  }

  &__input {
    padding: 5px 1px;
    width: space(9);
  }

  &__form {
    width: 40%;
  }

  &__spacer,
  &__image {
    width: 30%;
    max-width: 150px;
    max-height: 150px;
    object-fit: contain;
  }

  &__body {
    border-bottom: 1px solid color(elephant);
    padding: space(4);
    flex: 1 0 auto;
  }

  &__display {
    width: 75%;
    border-right: 1px solid color(elephant);
    max-height: 900px;
    overflow: hidden auto;
  }

  &__selected-items {
    width: 25%;
    max-height: 900px;
    overflow: auto;
  }

  &__selected--item {
    padding: space(2);
    margin: space(2);
    border: 1px solid color(elephant);
  }

  &__item-details {
    width: 80%;
  }

  &__item-size {
    width: 32%;
  }

  &__display-title,
  &__selected-items-title {
    @include text(h4);

    font-weight: bold;
    padding: space(4);
    border-bottom: 1px solid color(elephant);
  }

  &__selected-items-title {
    align-items: center;
  }

  &__selected--actions {
    @include stack(2, $direction: horizontal);

    & {
      padding: space(4);
      justify-content: center;
    }

    @include breakpoint(md, $prop: max-width) {
      @include stack(2);

      & {
        align-items: center;
      }
    }
  }

  &__number,
  &__name {
    width: 15%;
    word-wrap: break-word;
  }

  &__color {
    width: 10%;
    word-wrap: break-word;
  }

  &__button {
    margin-top: 1rem;
    height: space(4);
    width: space(4);
  }

  &__quantity {
    flex-wrap: wrap;
  }

  &__header,
  &__header--color {
    font-weight: bold;
    width: 15%;
  }

  &__header--color {
    width: 10%;
  }

  &__empty-search {
    flex-grow: 1;
  }

  &__cancel-button {
    color: color(cornflower-blue);

    &:hover {
      cursor: pointer;
    }
  }

  &__selected-item-details {
    @include stack(8, $direction: horizontal);

    @include breakpoint(md, $prop: max-width) {
      @include stack(2);
    }
  }

  &__selected-item-color {
    @include breakpoint(md, $prop: max-width) {
      margin-left: 0;
    }
  }
}

// stylelint-disable plugin/selector-bem-pattern
button.SelectionPanel__selected--button {
  width: space(10);
  height: space(7);

  @include breakpoint(md, $prop: max-width) {
    margin-left: 0;
  }
}

button.SelectionPanel__button,
button.SelectionPanel__selected--button {
  @include text(h6);

  border: 2px solid color(cornflower-blue);
  color: color(cornflower-blue);
  font-weight: bold;

  &:hover {
    color: color('snow');
    background-color: color(cornflower-blue);
    border: 2px solid color(cornflower-blue);
  }
}
