@import '~@moda/om';

.ShipmentFormNavigation {
  padding: spacing(4, 6);
  border-bottom: 1px solid color('fog');
  background-color: color('snow');
}

// stylelint-disable plugin/selector-bem-pattern
button.ShipmentFormNavigation__back-button,
button.ShipmentFormNavigation__next-button {
  border: 2px solid color(cornflower-blue);
  color: color(cornflower-blue);
  font-weight: bold;

  &:hover {
    background-color: color(cornflower-blue);
    border: 2px solid color(cornflower-blue);
    color: color('snow');
  }
}

button.ShipmentFormNavigation__submit-button {
  border: 2px solid color(cornflower-blue);
  color: color('snow');
  background-color: color(cornflower-blue);
  font-weight: bold;

  &:hover {
    background-color: color(cornflower-blue);
    border: 2px solid color(cornflower-blue);
    color: color('snow');
    font-weight: bold;
  }
}
