@import '@moda/om';

.PurchaseOrderReturnPanelModal {
  text-align: center;
  margin: 0 auto;
  width: 85%;
  z-index: 1;

  @include breakpoint(sm) {
    width: 50%;
  }

  &__confirm-modal-content,
  &__cancel-modal-content {
    background-color: color('snow');
    padding: space(5);

    @include breakpoint(sm) {
      padding: space(8);
    }
  }

  &__success-icon {
    color: color(money-good);
  }

  &__cancel-heading {
    font-weight: bold;
  }
}

// stylelint-disable plugin/selector-bem-pattern
a.Button.PurchaseOrderReturnPanelModal__confirm-button,
button.PurchaseOrderReturnPanelModal__cancel-button {
  @include text(h6);

  background-color: color('snow');
  border: 2px solid color(cornflower-blue);
  color: color(cornflower-blue);
  font-weight: bold;

  &:hover {
    border: 2px solid color(cornflower-blue);
    background-color: color(cornflower-blue);
    color: color('snow');
  }
}
