@import '~@moda/om';
@import '../../styles/variables';

.TopNavigation {
  padding: spacing(4, 6);
  position: sticky;
  border-bottom: 1px solid color(fog);
  background-color: color('snow');
  top: 0;
  z-index: z(nav);

  &__logout {
    text-transform: uppercase;
  }
}
