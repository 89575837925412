@import '@moda/om';

.PackingListPage {
  &__error {
    align-items: center;
    flex-direction: column;
    height: 100%;
    padding: spacing(10, 10);
    font-weight: bold;
  }
}

// stylelint-disable plugin/selector-bem-pattern
.PackingListPage__pdf-page {
  @include stack(4);

  & {
    align-items: center;
  }
}

.PackingListPage-page > canvas {
  max-width: 100%;
}
