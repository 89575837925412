@import '@moda/om';

.Carton {
  &__icon {
    color: color(cornflower-blue);
  }

  &__weight {
    width: space(10);
  }

  &__dimension,
  &__mass {
    bottom: -0.5rem;
    color: color(elephant);
    float: right;
    margin-bottom: -2rem;
    position: relative;
    right: 1.8rem;
  }

  &__dimension {
    bottom: space(6);
    right: space(2);
  }
}
