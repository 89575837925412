@import '@moda/om';

.ProgressBar {
  $self: &;

  position: relative;
  padding: spacing(4, 0);
  border-bottom: 1px solid color(elephant);
  background-color: color(noise);

  @include breakpoint(md) {
    padding: spacing(0, 0, 6);

    // stylelint-disable-next-line plugin/selector-bem-pattern
    &#{$self}--with-steps {
      padding: spacing(0, 0, 8);
    }
  }

  &--with-steps {
    padding: spacing(4, 0, 7);
  }

  &__content {
    @include stack(4);

    & {
      position: relative;
    }

    @include breakpoint(md) {
      @include stack(6);
    }
  }

  &__link {
    align-self: flex-start;
    text-decoration: none;

    &:hover,
    &:focus {
      opacity: 0.7;
    }

    @include breakpoint(md) {
      position: absolute;
      top: space(5);
      left: 0;
    }
  }

  &__link-text {
    @include thin-underline;
  }

  &__title {
    @include text(h5, $font: serif);

    align-self: center;

    @include breakpoint(md) {
      @include text(h3, $font: serif);
    }
  }

  &__steps {
    position: relative;
    align-self: center;
    width: 35rem;
    max-width: 100%;
    padding: spacing(0, 5);

    &::before {
      content: '';
      position: absolute;
      left: space(5);
      right: space(5);
      top: 50%;
      height: 1px;
      background-color: color(fog);
    }
  }
}
