@import '~@moda/om';

.ShippingDateSelector {
  background-color: color('snow');
  padding: spacing(6, 8);
  border-radius: space(1);
  box-shadow: 0 space(4) space(4) -1rem color(ink, 0.2);

  &__label {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }

  &__date-picker-wrapper {
    width: 100%;
  }

  &__header {
    text-align: start;
  }

  &__header::after {
    content: '*';
    color: color(cornflower-blue);
  }

  &__icon {
    position: absolute;
    padding-right: space(3);
  }
}

// stylelint-disable plugin/selector-bem-pattern
.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container input {
  padding: space(3);
  width: 100%;
  border: 1px solid color(cornflower-blue);
  border-radius: space(1);
}
