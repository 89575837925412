@import '@moda/om';

.FileUploader {
  background-color: color('snow');
  padding: spacing(6, 8);
  border-radius: space(1);
  box-shadow: 0 space(4) space(4) -1rem color(ink, 0.2);

  &__dropzone {
    align-items: center;
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: space(5);
    border: 2px color(noise);
    border-radius: space(1);
    border-style: dashed;
    background-color: color('snow');
    outline: none;
    transition: border 0.24s ease-in-out;
    cursor: pointer;
  }

  &__dropzone--active {
    border-color: color(cornflower-blue);
  }

  &__uploaded-files {
    padding: space(2);
    border: 1px solid color(cornflower-blue);
    border-radius: space(1);
  }

  &__icon {
    color: color(cornflower-blue);
  }
}
