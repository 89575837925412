@import '@moda/om';
@import '../../../styles/variables';

.PurchaseOrderDetailPanel {
  @include overflow-scroll;

  border: 1px solid color(elephant);
  max-height: 80vh;
  width: 75%;
  background-color: color('snow');

  @include breakpoint(md) {
    width: 69%;
  }

  &__header {
    padding: spacing(4);
    background-color: color('snow');
  }

  &__po-number {
    margin: 0 auto;
  }

  &__po-number-text {
    font-weight: bold;
  }

  &__download-link {
    margin: 0 auto;
    height: calc(100% - 150vh);
    color: color(cornflower-blue);
    cursor: pointer;
  }
}

// stylelint-disable plugin/selector-bem-pattern
button.PurchaseOrderDetailPanel__download-text {
  @include text(h5);

  color: color(cornflower-blue);
  font-weight: bold;
}

.PurchaseOrderDetailPanel__pdf-page {
  @include stack(4);

  & {
    align-items: center;
  }
}

.PurchaseOrderDetailPanel__pdf-page > canvas {
  max-width: 100%;
}
