@import '~@moda/om';

.ErrorPage {
  &__content {
    box-sizing: border-box;
    padding: 0 5vw;

    @include breakpoint(md) {
      text-align: left;
    }
  }

  &__message {
    width: 100%;
  }

  &__action-link {
    @include text(h6);

    width: 100%;
  }

  &__action {
    color: color(klein-blue);
  }

  &__image {
    padding: space(6);
  }
}
