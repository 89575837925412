@import '@moda/om';

.PackageDetails {
  background-color: color('snow');
  padding: spacing(6, 8);
  border-radius: space(1);
  box-shadow: 0 space(4) space(4) -1rem color(ink, 0.2);

  &__table {
    border-spacing: space(4);
    width: 100%;
  }

  &__header {
    text-align: start;
  }

  &__header::after {
    content: '*';
    color: color(cornflower-blue);
  }

  &__button {
    align-self: flex-start;
  }
}
