@import '@moda/om';
@import '../../styles/variables';

.TermsAndConditionsPage {
  text-align: center;
  margin: 0 auto;
  width: 85%;
  z-index: z(one);

  &__modal {
    text-align: center;
    margin: 0 auto;
    width: 70%;
    z-index: z(one);
  }

  &__modal-content {
    background-color: color('snow');
    padding: space(8);
  }

  &__mo-modal-header {
    display: block;
    margin: auto;
  }

  &__handshake-icon {
    color: color(cornflower-blue);
  }
}
// stylelint-disable plugin/selector-bem-pattern
button.Button.TermsAndConditionsPage__agree-button {
  border: 2px solid color(cornflower-blue);
  color: color(cornflower-blue);

  &:hover {
    border-color: color(cornflower-blue);
    color: color(cornflower-blue);
  }
}
