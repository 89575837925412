@import '~@moda/om';

.SelectionPanelEmptyState {
  text-align: center;
  height: 100%;

  &__img {
    padding: space(4);
  }
}
