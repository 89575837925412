@import '@moda/om';

.ShipmentConfirmationModal {
  text-align: center;
  margin: 0 auto;
  width: 85%;
  z-index: 1;

  @include breakpoint(sm) {
    width: 60%;
  }

  &__modal-content {
    background-color: color('snow');
    padding: space(5);

    @include breakpoint(sm) {
      padding: space(8);
    }
  }

  &__success-icon {
    color: color(money-good);
  }
}
