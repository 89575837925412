@import '~@moda/om';

.ShippingAddressFormFields {
  &__fields {
    @include stack(4);
  }

  &__group {
    @include breakpoint(sm) {
      @include stack(4, $direction: horizontal);
    }

    @include breakpoint(sm, $prop: max-width) {
      @include stack(4);
    }
  }

  &__field {
    flex: 1 0;
  }
}
