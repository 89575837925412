@import '@moda/om';

.PurchaseOrderReturnPanel {
  border: 1px solid color(elephant);
  max-height: 80vh;
  min-width: 225px;
  width: 32%;
  background-color: color('snow');

  &__scrollable {
    @include overflow-scroll;

    max-height: auto;
    height: auto;
    min-height: 40vh;
  }

  &__title {
    font-weight: bold;
    padding: space(5);
  }

  &__po-detail {
    display: block;
    width: space(10);
    font-weight: bold;
  }

  &__main-content {
    padding: 6%;
  }

  &__changed-content {
    padding-bottom: 6%;
    white-space: normal;
    display: block;
  }

  &__heading {
    line-height: space(4);
    padding: spacing(3, 0);
    display: block;
    white-space: normal;
    font-weight: bold;
  }

  &__individual-style {
    font-weight: bold;
  }

  &__link {
    text-align: left;

    &:hover {
      background-color: color(noise);
    }
  }

  &__submit {
    padding: space(5);
  }

  &__submit-section {
    padding-top: space(1);
  }

  &__cancel {
    align-items: center;
  }
}

// stylelint-disable plugin/selector-bem-pattern
.ControlLink.PurchaseOrderReturnPanel__cancel-link {
  @include text(h6);

  color: color(cornflower-blue);
  font-weight: bold;
}

button.PurchaseOrderReturnPanel__submit-button {
  @include text(h6);

  color: color(cornflower-blue);
  font-weight: bold;
  border-radius: space(2);
  border: 2px solid color(cornflower-blue);
}
