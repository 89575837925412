@import '@moda/om';

.Step {
  $self: &;

  position: relative;

  &__title {
    position: absolute;
    left: 50%;
    top: space(5);
    transform: translateX(-50%);
    white-space: nowrap;
  }
}
