@import '@moda/om';

.PurchaseOrderReturnPanelPOChange {
  &__heading {
    padding: space(4);
    font-weight: bold;
    display: block;
  }

  &__back-link-wrapper {
    padding: space(2);
  }

  &__back-link {
    @include stack(2, $direction: horizontal);

    & {
      justify-content: flex-start;
      align-items: center;
    }

    &:hover {
      text-decoration: none;
    }
  }

  &__section {
    padding: spacing(0, 5);
  }

  &__submit-section {
    padding-top: space(1);
    z-index: 1;
  }

  &__fields {
    @include overflow-scroll;

    max-height: 50vh;
    height: 45vh;
    min-height: 40vh;
  }

  &__submit {
    padding: space(5);
  }

  &__date-selector {
    padding: 0;
  }
}

// stylelint-disable plugin/selector-bem-pattern
button.PurchaseOrderReturnPanelPOChange__submit-button {
  @include text(h6);

  border: 2px solid color(cornflower-blue);
  border-radius: space(2);
  color: color(cornflower-blue);
  font-weight: bold;
  width: 100%;

  &:hover {
    background-color: color(cornflower-blue);
    border: 2px solid color(cornflower-blue);
    color: color('snow');
  }
}

label.PurchaseOrderReturnPanelPOChange__label {
  @include text(h6);

  color: color(ink);
}
