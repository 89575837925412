@import '~@moda/om';

.PurchaseOrder {
  &__main {
    padding: spacing(0, 10);
    height: 100vh;

    @include breakpoint(md, $prop: max-width) {
      padding: spacing(0, 4);
    }

    @include breakpoint(xs, $prop: max-width) {
      padding: spacing(0, 1);
    }
  }

  &__box {
    padding: spacing(5, 0);
    width: 100%;
  }
}
